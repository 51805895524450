import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitle,
  StyledA,
  GroupManageTitle,
  BottomLine,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"
import bgWeb from "../../images/bg-1.svg"
import bgMobile from "../../images/bg-2.svg"
import groupfunnels1 from "../../images/groupfunnels-1.png"

const H1ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  align-content: center;
`
const H1Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 10px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const TitleH1 = styled.h1`
  box-sizing: border-box;
  color: #fff;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleH3 = styled.h3`
  font-size: 24px;
  color: #33475b;
  text-align: start;
  margin: 2rem 0 0.5rem 0;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1000px);
  justify-content: center;
  padding: 15px 40px;
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
`
const GroupManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1000px, 1250px);
  padding: ${props => props.padding || `3rem 30px`};
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 92%;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 20px;
    padding-bottom: ${props => props.isRefund && `25px`};
    text-align: center;
  }
`
const TitleText = styled.p`
  font-size: ${props => props.fontSize || `18px`};
  text-align: ${props => props.textAlign || `center`};
  margin: ${props => props.margin || `1.7rem 0 1rem 0`};
  line-height: 1.6;
  color: #3d4d69;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  background-color: #fff;
  :nth-of-type(even) {
    background-color: #f8fcfc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `25px 0` : `25px 10px`)};
    margin: 0 20px;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`
const FeatureCompareElement = styled.div`
  margin: 0;
  color: #33475b;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `0` : `0 5px`)};
    font-size: ${props => (props.title ? `14px` : `13px`)};
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 575px) {
    display: none;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  &:hover {
    color: #fff;
    background-color: #33cbcf;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const PlanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
  background-color: #fff;
`
const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`
const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`
const TablePaddedContainer = styled.div`
  background-color: #eafafa;
  padding-top: 30px;
`
const ColoredContainer = styled.div`
  display: grid;
  padding: ${props => (props.isProsCons ? `45px 50px` : `30px 40px`)};
  margin: 30px 0;
  border-radius: 10px;
  background-color: ${props => props.bgColor || `#eafafa`};
  @media (max-width: 575px) {
    padding: ${props => (props.isProsCons ? `25px` : `10px 25px`)};
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  padding: 30px 0 1.8rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`
const WebView = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>{props.element.groupX}</FeatureCompareElement>
  </FeaturesCompareContainer>
)

const ColorButton = styled.div`
  padding: 16px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #33cdd1;
    background-color: #fff;
  }
`
const ResponsiveImageWeb = styled.img`
  @media (max-width: 575px) {
    display: none;
  }
`
const ResponsiveImageMobile = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const CustomList = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 25px 15px;
  padding: 25px 0;
  align-items: center;
`
const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || `#92A2FA`};
`
const ListText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  color: #33475b;
`
export const StyledList = styled.li`
  line-height: 2;
`

export default ({ data, location }) => (
  <>
    <SEO
      title="Groupfunnels Alternative and Review in 2024"
      description="Check this article if you are looking for a better Groupfunnels alternative. Use Groupboss that has more features than Groupfunnels."
      pathname={location.pathname}
    />

    <H1ImageContainer>
      <ResponsiveImageWeb src={bgWeb} alt="home-background" width="100%" />
      <ResponsiveImageMobile
        src={bgMobile}
        alt="home-background mobile"
        width="100%"
      />
      <H1Container>
        <TitleH1>The Best Group Funnels Alternative and Review in 2024</TitleH1>
      </H1Container>
    </H1ImageContainer>

    <TitleTextContainer>
      <TitleText>
        Group Funnels lets you generate unlimited leads from Facebook groups by
        saving answers to the group qualifying questions. You can collect the
        answers to a google sheet only. Integrating software like Zapier is
        needed to send the leads to other email marketing autoresponders.
      </TitleText>

      <TitleText>
        What if you can do all the above tasks with a single click that Group
        Funnels do with multiple steps? Yes, it is possible with Groupboss which
        is considered as the Group Funnels alternative.
      </TitleText>
      <TitleText>
        Explore the best Group Funnels alternative for free.
      </TitleText>
    </TitleTextContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <TitleTextContainer>
      <CommonTitle
        fontSize="32px"
        fontWeight="700"
        margin="4.5rem 0"
        color="#33475B"
        style={{ textAlign: `center` }}
      >
        Why Should You Choose Groupboss over Group Funnels?
      </CommonTitle>
      <TitleText>
        Groupboss helps you to collect leads in Google Sheet, Dashboard, and
        email autoresponders with a single click only.
      </TitleText>
      <TitleText>
        On the other hand, you can collect leads in the google sheet only by
        using Group Funnels and you need Zapier to send them in autoresponders,
        which is a time-consuming process.
      </TitleText>
      <TitleH3>
        Have a look at the differences in features between Groupboss and Group
        Funnels
      </TitleH3>
    </TitleTextContainer>

    <TablePaddedContainer>
      <FeaturesCompareContainer title style={{ background: `#eafafa` }}>
        <FeatureCompareElement title>
          Features and Service{" "}
        </FeatureCompareElement>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gb_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupboss logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gf_logo.childImageSharp.gatsbyImageData}
              alt="groupleads logo"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gl_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupleads logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
      </FeaturesCompareContainer>

      <FeatureCompareRow
        featureText="Google Sheets Integration"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Dashboard"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Integration with Autoresponders"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Zapier Required"
        element={{
          groupboss: "No (Optional)",
          groupX: "Yes",
        }}
      />
      <FeatureCompareRow
        featureText="Custom & Lookalike Audience "
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Download all data from the dashboard in CSV or Excel format"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Chat Support"
        element={{
          groupboss: "Available",
          groupfunnels: "Not available",
        }}
      />
      <FeatureCompareRow
        featureText="Support in Email"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Refund Policy"
        element={{ groupboss: "14 Days", groupX: "30 Days" }}
      />

      <FeatureCompareRow
        featureText="Exclusive Support through Zoom/Skype/Gmeet"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Free Trial"
        element={{
          groupboss: "7 days",
          groupX: "No free trial",
        }}
      />

      <FeatureCompareRow
        featureText="Free set up Call"
        element={{
          groupboss: "Yes",
          groupX: "Yes",
        }}
      />

      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 Days Free Trial at Groupboss</FreeTrialButton>
        </Link>
      </div>
    </TablePaddedContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>
        Why do people look for Group Funnels alternatives?
      </GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        There are multiple reasons why people look for Group Funnels
        alternatives. Let’s explore some of the reasons.
      </TitleText>

      <TitleH3>1. Complex lead collection process</TitleH3>
      <TitleText textAlign="start">
        To save the Facebook group answers, you need to configure your Group
        with Group Funnels. You need to put the link of a Google sheet. Once you
        approve the member, data will be stored in Group Funnels for a limited
        time. You need to push the data to Google sheet and delete from the
        database.
      </TitleText>

      <TitleH3>2. Minimal Features</TitleH3>
      <TitleText textAlign="start">
        With Group Funnels, you get the scope to collect the answers to the
        Facebook group questions to a Google sheet only. Other than this there
        are no other remarkable features.
      </TitleText>

      <TitleH3>3. No integration with email marketing autoresponders</TitleH3>
      <TitleText textAlign="start">
        Group Funnels doesn’t have integration with any email marketing
        autoresponders. You need to use integrating software like Zapier,
        Integrately, Pabbly, etc to send the leads to your favourite email
        marketing autoresponders.
      </TitleText>

      <TitleH3>4. Poor customer experience</TitleH3>
      <TitleText textAlign="start">
        It seems Group Funnels lack in managing customers efficiently. Users are
        not satisfied with the service and support they receive.
      </TitleText>

      <GroupManageTitle>
        Why Should You Choose Groupboss over Group Funnels?
      </GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Groupboss is more advanced and equipped with more features than Group
        Funnels. You can take your Facebook group lead generation process to the
        next level with Groupboss.
      </TitleText>

      <TitleH3>
        Let’s look at a few top reasons you should choose Groupboss over Group
        Funnels.
      </TitleH3>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>1. Number of Features</TitleH3>
        <TitleText textAlign="start">
          The number of features available in Groupboss are not there in Group
          Funnels. The top features of Groupboss are:
        </TitleText>
        <TitleText textAlign="start">
          <li>Google sheet integration.</li>
          <li>
            Dedicated dashboard to keep the data saved other than google sheet.
          </li>{" "}
          <li>Integration with email marketing autoresponders</li>
          <li>
            Facebook custom and lookalike audience creation from Groupboss
            dashboard.
          </li>{" "}
          <li>Unlimited membersship approval</li>
          <li>
            Option to download data in Excel and CSV format from Groupboss
            dashboard.
          </li>
        </TitleText>

        <TitleText textAlign="start">
          On the other hand, Group Funnels has the feature of Google sheet
          integration only. That means you can collect the data in a google
          sheet only and you will have to follow multiple steps for that.
        </TitleText>
      </GroupManageTitleContainer>
    </div>

    <div style={{ backgroundColor: `#eafafa`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>2. Pricing</TitleH3>
        <TitleText textAlign="start">
          Pricing is the main concern for all before purchasing anything. If it
          is software, you need to evaluate what you will get in exchange for
          your payment. Keeping all these in mind, Groupboss has got the most
          competitive pricing.
        </TitleText>
        <TitleText textAlign="start">
          Group Funnels seems charging much higher compared to the feature they
          are offering.
        </TitleText>

        <TitleH3>
          Pricing comparison between Groupboss and Group Funnels
        </TitleH3>
        <TitleText textAlign="start">
          Let’s see the difference between the pricing plans to give you a clear
          understanding.
        </TitleText>
      </GroupManageTitleContainer>

      <PlanCardContainer>
        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </div>

          <PlanCardText title>
            $45
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / quarter
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Quarterly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $99
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $189
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>20</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>

        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gf_logo.childImageSharp.gatsbyImageData}
              alt="gf_logo"
            />
          </div>
          <PlanCardText title>No Yearly Plan</PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Unavailable</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>Unavailable</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>$297</PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>One-Time</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>
      </PlanCardContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#F4F8FF` }}>
      <TitleH3>3. Better user experience of Groupboss</TitleH3>
      <TitleText textAlign="start">
        You will get the best customer experience from the Groupboss team.
        Keeping user satisfaction at the top priority, team Groupboss always
        tries to address any issue and query of the users as soon as possible.
      </TitleText>

      <TitleText textAlign="start">
        Let’s see what Groupboss users are saying about their experience-
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.better_ux.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.better_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>4. Detailed Tutorials</TitleH3>

      <TitleText textAlign="start">
        Groupboss provides textual and video training for the onboarding
        process, integrations, product updates, and other topics. You will get
        documentation and tutorials on the support page and YouTube channel.
        With these assistance resources, Groupboss makes the onboarding process
        a lot easier.
      </TitleText>

      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.detailed_tutorials.childImageSharp.gatsbyImageData}
            alt="feedback detail tutorials"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={
              data.detailed_tutorials_mobile.childImageSharp.gatsbyImageData
            }
            alt="feedback detail tutorials"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>

      <TitleText textAlign="start">
        <strong>Note:</strong> In the Groupboss YouTube channel, you will get
        useful tutorials on Facebook group features. Stay connected with
        Groupboss YouTube channel to learn further.
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>5. Clean and Clear UI/UX</TitleH3>
      <TitleText textAlign="start">
        User interface(UI) is one of the key factors of measuring the quality of
        a software. Groupboss team kept it right to the point. Positive feedback
        from the users indicates how they are liking the UI of Groupboss.
      </TitleText>
      <TitleText textAlign="start">
        You have the scope to compare the UI of Groupboss and Group Funnels.
        You’ll see the differences.
      </TitleText>
      <ImageContainer>
        <GatsbyImage
          image={data.groupboss_poster.childImageSharp.gatsbyImageData}
          alt="groupboss poster"
        />
      </ImageContainer>
      <TitleText textAlign="start">
        Check the below message to see what Groupboss users are telling about
        its UI.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.clear_ux.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.clear_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FDFAF3` }}>
      <TitleH3>6. Integration with email marketing autoresponders</TitleH3>
      <TitleText textAlign="start">
        This is another differentiating feature for which you can choose
        Groupboss over Group Convert. You can connect your Facebook group with
        any of the{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://groupboss.io/integrations/"
        >
          25+ email marketing autoresponders
        </StyledA>{" "}
        that Groupboss has already integrated. With a single click, you get the
        scope to sync the leads in the email marketing autoresponders.
      </TitleText>
      <TitleText textAlign="start">
        <li>MailChimp,</li>
        <li>ActiveCampaign,</li>
        <li>Getresponse, </li>
        <li>ConvertKit, </li>
        <li>Lemlist, </li>
        <li>Snov.io, </li>
        <li>SendFox, </li>
        <li>Moosend, </li>
        <li>MailerLite, </li>
        <li>Automizy, </li>
        <li>Sendgrid, </li>
        <li>Sendinblue, </li>
        <li>Klaviyo,</li>
        <li>Drip,</li>
        <li>Hyros(MarketHero),</li>
        <li>Gist,</li>
        <li>Pabbly,</li>
        <li>GoHighLevel,</li>
        <li>EmailOctopus</li>
        <li>Omnisend</li>
        <li>BenchmarkEmail</li>
        <li>ClickFunnels</li>
        <li>Systeme.io</li>
        <li>Beehiiv</li>
        <li>HubSpot</li>
        <li>Platfmorm.ly</li>
      </TitleText>
      <TitleText textAlign="start">
        This feature is not available in Group Funnels. You need to do this task
        manually or use other tools to pass the leads from Google sheet to email
        marketing autoresponders.
      </TitleText>
    </GroupManageTitleContainer>
    <GroupManageTitleContainer>
      <GroupManageTitle>Group Convert Review Summary</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        So far, you have come to know many things about Group Convert including
        its features and pricing. Group Convert is used by many group owners for
        collecting leads though they have very limited features. It might be the
        reason that they do not know about other alternatives available with
        better pricing options.
      </TitleText>
      <TitleText textAlign="start">
        Let’s look at the pros and cons of Group Convert
      </TitleText>
      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <TitleH3 style={{ margin: `0 0 1rem` }}>Pros</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>
            Automation of lead collection from Facebook groups
          </ListText>
          <ColoredDot />
          <ListText>Google sheet integration</ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            Set process is a bit easy as there are not many features
          </ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>Free trial available</ListText>
        </CustomList>

        <TitleH3 style={{ margin: `1.5rem 0 1rem` }}>Cons</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>Very limited features</ListText>
          <ColoredDot />
          <ListText>
            No integration with email marketing autoresponders.{" "}
          </ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            Very few tutorials and documentation for the set-up process
          </ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>No instant chat support</ListText>
          <ColoredDot bgColor="#EBCD89" />
          <ListText>Higher pricing compared to available features. </ListText>
        </CustomList>
      </ColoredContainer>

      <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
        <GroupManageTitleContainer>
          <TitleH3>7. Download data from dashboard </TitleH3>
          <TitleText textAlign="start">
            From Groupboss dashboard you get the scope to download your data in
            Excel or CSV format. You have the scope to generate a custom
            lookalike audience from the data with a single click.
          </TitleText>

          <ImageContainer>
            <img
              src={groupfunnels1}
              alt="Download data from dashboard"
              width="100%"
            />
          </ImageContainer>

          <TitleText textAlign="start">
            You can not imagine these features in Group Funnels.
          </TitleText>
        </GroupManageTitleContainer>
      </div>

      <GroupManageTitle>Verdict</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        So, you have come to know the differences between Groupboss and Group
        Funnels. Money matters in every field, right?
      </TitleText>
      <TitleText textAlign="start">
        It’s your turn to decide which one to use.
      </TitleText>
      <TitleText textAlign="start">
        Would you love to try the best Group Funnels alternative? Just click on
        the below sign up button and explore further.
      </TitleText>
      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 Days Free Trial at Groupboss</FreeTrialButton>
        </Link>
      </div>
      <TitleText textAlign="start">
        If you are looking into other similar software and their alternatives,
        you may look at the following articles.
      </TitleText>
      <ul>
        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/grouptrackcrm-alternative-and-review/"
          >
            The Best GroupTrack CRM Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupconvert-alternative/"
          >
            The Best Groupconvert Alternatives and Review
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupx-alternative/"
          >
            The Best groupX Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupkit-alternative/"
          >
            The Best GroupKit Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupbuddy-alternative/"
          >
            The Best GroupBuddy Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupspro-alternative/"
          >
            The Best Groups Pro Alternative and Review.
          </StyledA>{" "}
        </StyledList>
      </ul>
    </GroupManageTitleContainer>
    <TestimonialIndex alternative="groupleads" />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupx-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "gb-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gf_logo: file(relativePath: { eq: "gf_logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo_mobile: file(relativePath: { eq: "gb-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo_mobile: file(relativePath: { eq: "gl-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    poor_customer: file(
      relativePath: { eq: "feedback-poor-customer-support.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux: file(
      relativePath: { eq: "feedback-better-user-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux: file(relativePath: { eq: "feedback-clean-clear-UX.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    groupboss_poster: file(relativePath: { eq: "shot-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials: file(
      relativePath: { eq: "feedback-detailed-tutorials.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    poor_customer_mobile: file(
      relativePath: { eq: "feedback-poor-customer-support-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux_mobile: file(
      relativePath: { eq: "feedback-better-user-experience-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux_mobile: file(
      relativePath: { eq: "feedback-clean-clear-UX-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials_mobile: file(
      relativePath: { eq: "feedback-detailed-tutorials-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
